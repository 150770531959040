@import "../../abstracts/variables"
@import "../../abstracts/mixins"

/*Modal Solicitar*/

.mSolicitar
	width: 480px
	.mSolTitle
		+font-size(18px)
		font-weight: 700
		margin-bottom: $spacer
		text-align: center
	.mSolTitleFont
		+font-size(24px)
	.mSolBundle
		+flex
		+justifyCont(center)
		+font-size(18px)
		font-weight: 700
		margin-bottom: $spacer2
		p
			position: relative
			padding-left: 23px
			i
				+font-size(20px)
				position: absolute
				left: 0
				top: 2px
			+ p
				margin-left: $spacer3
				&:before
					+font-size(20px)
					content: '+'
					position: absolute
					left: -19px
					top: -1px
					display: block
	.mSolDesc
		margin-bottom: $spacer3
	.mSolSubs
		+flex
		+flexDir(column)
		+alignItem(center)
		p
			text-align: center
			font-weight: 500
			width: 100%
			margin-bottom: $spacer2
		[class*="btn"]
			width: 240px
			+ [class*="btn"]
				margin-top: $spacer2
	.mSolForm
		p
			text-align: center
			font-weight: 500
			width: 100%
			margin-bottom: $spacer2
		form
			+flex
			+flexDir(column)
			+justifyCont(space-between)
			margin-bottom: $spacer2
			fieldset
				width: 100%
				+ fieldset
					margin-top: $spacer2
					input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="date"], input[type="tel"]
						height: auto
						padding: $spacer $spacer2
			.mSolFormActions
				+flex
				+flexDir(column)
				+alignItem(center)
				width: 100%
				margin-top: $spacer2
				[class*="btn"]
					width: 200px
					margin-bottom: $spacer2
	
	+mediaMin640
		.mSolForm
			form
				+flexDir(row)
				+flexWrap
				fieldset
					width: 120px
					.select
						min-width: 120px
					+ fieldset
						width: calc(100% - 130px)
						margin-top: 0
				
.mSolicitarContact
	.mSolicitarContactTitle
		+font-size(24px)
		font-weight: 700
		text-align: center
		margin-bottom: $spacer2 + 4
	.mSolicitarContactSelect
		label
			display: block
			font-weight: 500
			margin-bottom: $spacer0
		+ .mSolicitarNumb
			margin-top: $spacer2

	.mSolicitarNumb
		label
			display: block
			font-weight: 500
			margin-bottom: $spacer0
	.mSolicitarContactAut
		margin-top: $spacer2 + 4
		label
			span
				+font-size(12px)
				line-height: 18px
	.mSolicitarContactAct
		+flex
		+flexDir
		+justifyCont
		margin: 0 auto
		margin-top: $spacer3 - 4
		max-width: 100%
		+mediaMin640
			max-width: 220px
		button
			+ a
				margin-top: $spacer2 + 4
		a
			text-align: center
.mSolicitarNotice
	+flex
	+justifyCont
	+flexDir
	.statusFormImg
		margin-bottom: $spacer2
		+ .mSolicitarNotTitle
			margin-top: 0
	
	.mSolicitarNotTitle
		+flex
		+justifyCont
		+font-size(24px)
		font-weight: 700
		text-align: center
		p
			max-width: 280px
		+ .mSolicitarNotInfo
			margin-top: $spacer2
	.mSolicitarNotInfo
		p
			text-align: center
			font-weight: 500
			span
				color: $azul5
				font-weight: 700
		+ .mSolicitarNotAct
			// margin-top: $spacer4
			margin-top: $spacer2
	.mSolicitarNotAct
		+flex
		+justifyCont
.mSolicitarSales
	width: 620px
	.mSolicitarSalesCont
		.mSolSales
			.mSolSalTitle
				+font-size(18px)
				font-weight: 700
				margin-bottom: $spacer
			.mSolSalDesc
				margin-bottom: $spacer2
			> fieldset
				margin-bottom: $spacer2
				// label
				// 	+ .select
			label
				display: block
				font-weight: 700
				margin-bottom: $spacer0
			.mSolSalesCol2
				+flex
				+ .mSolSalesCol2
					padding-top: $spacer2
				margin: 0 (-$spacer3 / 2 )
				fieldset
					width: calc(50% - #{$spacer3})
					margin: 0 $spacer3 / 2 
					label
			.mSolSalesActions
				+flex
				+flexDir(column)
				+alignItem(center)
				width: 100%
				margin-top: $spacer3
				[class*="btn"]
					width: 200px
					margin-bottom: $spacer2
.ul-list-style
	list-style: initial