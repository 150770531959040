// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap')

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-BoldItalic.eot')
	src: url('../../../Assets/fonts/Gibson-BoldItalic.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-BoldItalic.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-BoldItalic.woff') format("woff"), url('../../../Assets/fonts/Gibson-BoldItalic.ttf') format("truetype")
	font-weight: bold
	font-style: italic
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-Bold.eot')
	src: url('../../../Assets/fonts/Gibson-Bold.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-Bold.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-Bold.woff') format("woff"), url('../../../Assets/fonts/Gibson-Bold.ttf') format("truetype")
	font-weight: bold
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-Italic.eot')
	src: url('../../../Assets/fonts/Gibson-Italic.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-Italic.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-Italic.woff') format("woff"), url('../../../Assets/fonts/Gibson-Italic.ttf') format("truetype")
	font-weight: normal
	font-style: italic
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-Light.eot')
	src: url('../../../Assets/fonts/Gibson-Light.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-Light.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-Light.woff') format("woff"), url('../../../Assets/fonts/Gibson-Light.ttf') format("truetype")
	font-weight: 300
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-LightIt.eot')
	src: url('../../../Assets/fonts/Gibson-LightIt.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-LightIt.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-LightIt.woff') format("woff"), url('../../../Assets/fonts/Gibson-LightIt.ttf') format("truetype")
	font-weight: 300
	font-style: italic
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-Regular.eot')
	src: url('../../../Assets/fonts/Gibson-Regular.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-Regular.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-Regular.woff') format("woff"), url('../../../Assets/fonts/Gibson-Regular.ttf') format("truetype")
	font-weight: normal
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-SemiBold.eot')
	src: url('../../../Assets/fonts/Gibson-SemiBold.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-SemiBold.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-SemiBold.woff') format("woff"), url('../../../Assets/fonts/Gibson-SemiBold.ttf') format("truetype")
	font-weight: 600
	font-style: normal
	font-display: swap

@font-face
	font-family: 'Gibson'
	src: url('../../../Assets/fonts/Gibson-SemiboldIt.eot')
	src: url('../../../Assets/fonts/Gibson-SemiboldIt.eot?#iefix') format("embedded-opentype"), url('../../../Assets/fonts/Gibson-SemiboldIt.woff2') format("woff2"), url('../../../Assets/fonts/Gibson-SemiboldIt.woff') format("woff"), url('../../../Assets/fonts/Gibson-SemiboldIt.ttf') format("truetype")
	font-weight: 600
	font-style: italic
	font-display: swap